import * as React from 'react'
import { Wrapper } from '../layout'
import theme from '../styles/theme'
import GlobalStyles from '../styles/globalStyles'
import Head from '../components/Head'
import styled, { keyframes, ThemeProvider } from 'styled-components'
import { GatsbyImageFluidProps } from 'gatsby-image'
import { BarraNavegacion } from '../components/BarraNavegacion'
import Img from 'gatsby-image'
import { graphql, useStaticQuery } from 'gatsby'
import { media } from '../styles/styleUtils'
import { PieDePagina } from '../components/PieDePagina'

interface IndexPageProps {
  location: {
    pathname: string
  }
  data: {
    site: {
      siteMetadata: {
        title: string
        description: string
      }
    }
  }
}

export const Parrafo = styled.p`
  text-align: justify;
  font-size: 1.2rem;
  margin: 2rem 0 2rem 0;
`
const scale = keyframes`
  from {
    transform: scale(1);
  }

  to {
    transform: scale(1.2);
  }
`

const ImagenQuienesSomos = styled(Img)`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  object-fit: cover;
  ${media.md`
  `}
`

const WrapperImageQuienesSomos = styled.div`
  position: relative;
  overflow: hidden;
  padding-bottom: 5%;
  ${media.md`
  `}
`

const TituloSeccion = styled.h3`
  position: absolute;
  bottom: 3.5rem;
  color: white;
  font-weight: 900;
  right: 3.5rem;
  font-size: 5rem;
  text-align: center;
  text-shadow: 3px 3px grey;
  z-index: 2;
  ${media.md`
    font-size: 2rem;
  `}
  ${media.sm`
    font-size: 1.5rem;
    bottom: 1.5rem;
    right: 1.5rem;
  `}
`
export const WrapperBody = styled.div`
  display: block;
  margin-top: 2rem;
  ${media.md`
    margin: 1rem;
  `}
`

export const Negrita = styled.span`
  font-weight: bold;
`

export default ({ data, location }: IndexPageProps) => {
  const ImageQueryBonoRegalo = useStaticQuery(graphql`
    query ImageQueryBonoRegalo {
      QuienesSomos: file(relativePath: { eq: "imagenes/bono-regalo.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1200) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `)
  return (
    <>
      <Wrapper>
        <GlobalStyles />
        <Head pathname={location.pathname} />
        <ThemeProvider theme={theme}>{data}</ThemeProvider>
        <BarraNavegacion />
        <WrapperBody>
          <WrapperImageQuienesSomos>
            <ImagenQuienesSomos
              alt={'Imagen del bono regalo'}
              fluid={ImageQueryBonoRegalo.QuienesSomos.childImageSharp.fluid}
              objectFit="cover"
              objectPosition="50% 50%"
            ></ImagenQuienesSomos>
          </WrapperImageQuienesSomos>
          <Parrafo>
            Estás pensando en hacer un regalo diferente y original? No lo dudes,
            regala una experiencia gastronómica en restaurante Borrasco.
          </Parrafo>
          <Parrafo>
            Tenemos disponibles tres modalidades de bono regalo:
          </Parrafo>
          <Parrafo>
            <Negrita>Bono 1</Negrita>: Menú Degustación para 2 personas + bodega
            + café = 90€ (iva incluido)
          </Parrafo>
          <Parrafo>
            <Negrita>Bono 2</Negrita>: Menú fin de semana para 2 personas +
            bodega + café = 46€ (iva incluido)
          </Parrafo>
          <Parrafo>
            <Negrita>Bono 3</Negrita>: comida o cena para dos personas valorada
            en lo que cada uno considere oportuno.
          </Parrafo>
          <Parrafo>Contacta con nosotros para más información en :</Parrafo>
          <Parrafo>
            <a href="tel:+34948 53 05 61">948 53 05 61</a>
          </Parrafo>
          <Parrafo>
            <a href="tel:+34649943878">649 943 878</a>
          </Parrafo>
          <Parrafo>
            <a href="mailto:info@restauranteborrasco.com">
              info@restauranteborrasco.com
            </a>
          </Parrafo>
        </WrapperBody>

        <PieDePagina />
      </Wrapper>
    </>
  )
}
